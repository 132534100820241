import React from 'react';
import styled from '@emotion/styled';
import { color } from '../../../utils/style';
import ContactForm from './ContactForm';

const Wrapper = styled('div')`
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid ${color.transparentWhite1};
`;

const ContactAll = () => {
  return (
    <Wrapper>
      <ContactForm/>
    </Wrapper>
  );
};

export default ContactAll;